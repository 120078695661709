<template>
  <div>
    <div v-for="file in displayedEntries" :key="file.id">
      <b-card  v-if="cardDisplay == 'detailed'" class="mb-2 myproj-card">
        <b-row class="d-flex myproj-upperrow">
          <div class="d-flex align-items-center library-card-tag">
            <img :src="'/assets/images/icon_library_documenttype.svg'"/>
            <span>{{file.mymeType}}</span>
          </div>
          <span class="p-new-color d-none">{{ $t('file.new') }}</span>
          <span class="library-card-date openSans-400 fz-12 mx-2">{{ $t('file.updated') }} {{ file.uploaded | formatTime }} </span>

          <span v-if="file.status === ':INGESTED'" class="openSans-300 fz-12 library-card-date mx-2 completed-status">
            <img class="va-middle mx-2 icon-finished" :src="'/assets/images/icon_finished.svg'"/>
            <span class="va-middle">{{ $t('file.processingComplete') }}</span>
          </span>
          <span v-else class="openSans-300 fz-12 library-card-date mx-2 processing-status">
            <img class="va-middle mx-2" :src="'/assets/images/icon_processing_small.svg'"/>
            <span class="va-middle">{{ $t('file.processingIngestion') }}</span>
          </span>
          <popover-dots v-if="canModifyLibrary" :document="file" :show-msg-box="showMsgBox"/>
        </b-row>
        <b-row class="myproj-lowerrow">
          <router-link class="text-left myproj-projectname" :to="file.path">{{ file.name }}</router-link>
        </b-row>
      </b-card>

      <b-card v-if="cardDisplay == 'simplified'" class="mb-2 myproj-card simplified-file-card">
        <b-row class="d-flex simplified-row">
          <router-link class="text-left myproj-projectname simplified-file-name"
                       :to="{path: file.path, query: {page: currentPage}}">{{ file.name }}</router-link>
          <span class="library-card-date openSans-400 fz-12 mx-2">{{ $t('file.updated') }} {{ file.uploaded | formatTime }}</span>
          <div class="d-flex align-items-center library-card-tag simplified-file-type library-card-date">
            <img :src="'/assets/images/icon_library_documenttype.svg'"/>
            <span>{{file.mymeType}}</span>
          </div>
          <span v-if="file.status === ':INGESTED'" class="openSans-300 fz-12 library-card-date mx-2 completed-status">
            <img class="va-middle mx-2 icon-finished" :src="'/assets/images/icon_finished.svg'"/>
            <span class="va-middle">{{ $t('file.processingComplete') }}</span>
          </span>
          <span v-else class="openSans-300 fz-12 library-card-date mx-2 processing-status">
            <img class="va-middle mx-2" :src="'/assets/images/icon_processing_small.svg'"/>
            <span class="va-middle">{{ $t('file.processingIngestion') }}</span>
          </span>
          <popover-dots v-if="canModifyLibrary" :document="file" :show-msg-box="showMsgBox"/>
        </b-row>
      </b-card>
    </div>

    <b-pagination v-if="totalEntries > filesPerPage"
      v-model="currentPage"
      :total-rows="totalEntries"
      :per-page="filesPerPage"
      @change="pageChangeHandler"
      first-number
    last-number/>
  </div>
</template>

<script>
import tools from '@/modules/common/utils/tools'
import moment from 'moment'
import PopoverDots from "@/modules/common/components/PopoverDots";
import ProjectService from "@/modules/projects/services/ProjectService";

export default {
  name: 'my-projects-list',
  components: {PopoverDots},
  props: {
    source: Array,
    cardDisplay: String,
    projectId: String
  },
  data() {
    return {
      currentPage: this.$route.query.page*1 || 1,
      filesPerPage: 5,
      documents: [],
      totalEntries: 0,
      displayedEntries: []
    }
  },
  filters: {
    formatTime: tools.formatTime
  },
  methods: {
    pageChangeHandler (value){
      let start = (value - 1) * this.filesPerPage
      this.displayedEntries = this.documents.slice(start, start + this.filesPerPage)
    },
    isDayAgo(project) {
      let end = new Date()
      let timeUtc = moment.utc(end).format()
      return !moment(project.started).isBefore(timeUtc, 'day')
    },
    showMsgBox(doc, refs) {
      let boxOne = ''
      let currentPopover = refs[`popover-${doc.id}`]
      currentPopover.$emit('close')

      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this document?', {
        title: 'Wait',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'popUp-primary',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'popUp-cancel',
        centered: true,
        hideHeaderClose: false,
        footerClass: 'p-2'
      })
        .then(value => {
          boxOne = value
          if(boxOne === true) {
            this.removeDocument(doc.id, doc.name)
          }
        })
        .catch(err => {
          console.log(err)
        })

    },
    removeDocument(docId, docTitle) {
      ProjectService.removeDocument(this.projectId, docId, docTitle).then(() => {
        this.documents = this.documents.filter(doc => doc.id != docId)
        this.totalEntries = this.documents.length
        this.pageChangeHandler(this.currentPage)
      })
    },
  },
  computed: {
    canModifyLibrary() {
      return this.$store.getters.canModifyLibrary
    }
  },
  watch: {
    source: function(documents) {
      this.documents = documents
      this.totalEntries = documents.length
      this.pageChangeHandler(this.currentPage)
    }
  },
  created() {
    this.documents = this.source
    this.totalEntries = this.source.length
    this.pageChangeHandler(this.currentPage)
  }
}
</script>
<style scoped>

.btn-primary-myproj span{
  vertical-align: sub;
}

.p-new-color {
  color: #09c6dc;
  padding-left: 10px;
}

.myproj-projectname {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--viridian);
  padding-left:22px;
  width:100%;
  max-width:794px;
  margin:5px 30px auto 0px;
  cursor:pointer;
}

.myproj-card {
  height: auto;
  min-height: 104px;
}

.myproj-card div.card-body {
    padding: 0px !important;
}

.myproj-upperrow {
  margin: 7px 0px 16px 0px
}
.myproj-lowerrow {
  margin: 0px 0px 18px 0px;
}

.library-card-tag{
  margin-bottom:0px;
}

.library-card-date{
  margin-top:auto;
  margin-bottom: auto;
}
</style>