<template>
  <div class="container-base section-container-padding bg-white">
    <my-project-header
      :title="project.name"
      :isNew="project.isDayAgo"
      :lastUpdate="project.started"
      :pipelinesUsed="project.pipelinesUsed"
      :showInput="showInput">
      <!-- slot -->
      <template v-slot:inputName>
        <b-form-input class="li-header-title openSans-100 fz-28 input-margin"
          v-model="project.name"
          v-if="showInput"
          size="sm"
          ref="projectName"
          v-on:keyup.enter="submitEdit"
          v-on:keyup.esc="cancelEdit"
          @blur="submitEdit"
          ></b-form-input>
      </template>
      <template v-slot:popover>
        <img id="popover-1-bottom" class="margin-dot" :src="'/assets/images/icon_moreoptions.svg'"/>
        <b-popover ref="popover" class="my-project-popover" target="popover-1-bottom" placement="bottom">
          <div>
            <ul class="project-action-list padding-zero">
              <li @click="renameProject">
              <img :src="'/assets/images/icon_rename.svg'"/>
              <span>{{ $t('proyect.general.rename') }}</span>
              </li>
              <li @click="showMsgBox">
              <img :src="'/assets/images/icon_delete.svg'"/>
              <span>{{ $t('proyect.general.remove') }}</span>
              </li>
            </ul>
          </div>
        </b-popover>
      </template>
    </my-project-header>
    <div class="myproj-container">
      <b-row>
        <b-tabs v-model="step" class="col-12" content-class="mt-3">
          <b-tab title="Ingested List">
            <template v-slot:title>
              <img :src="'/assets/images/icon_di_ingestedlist.svg'"/><span>{{ $t('proyect.general.ingestedList') }}</span>
            </template>
            <div class="col-12 margin-cards">
              <my-files-list :cardDisplay="cardDisplay" :source="project.files" :projectId="project.projectId"/>
            </div>
          </b-tab>
          <b-tab title="Configure Ingestion">
            <template v-slot:title>
              <img :src="'/assets/images/icon_li_ingestion.svg'"/><span>{{ $t('proyect.general.configureIngestion') }}</span>
            </template>
            <configure-ingestion-tab :project="project"/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <img :src="'/assets/images/icon_li_ingestion.svg'"/><span>{{ $t('proyect.general.enrichDoc') }}</span>
            </template>
            <br>
            <project-utility-list
              :utility-sets="suggested"
              :title="$t('utilitySets.buttonGroup.featured')"/>
            <br>
            <project-utility-list
              :utility-sets="utilities"
              :title="$t('utilitySets.buttonGroup.all')"/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <img :src="'/assets/images/icons-project-details-sm-green.svg'"/><span>{{ $t('proyect.general.projectDetail') }}</span>
            </template>
            <b-row class="project-detail-first-row">
              <div class="pd-block">
                <span class="fz-27 openSans-400 pd-header-value">{{ingestionSources.length}} </span>
                <span class="fz-16 openSans-100 pd-header-label d-block">Ingestion Pipelines</span>
              </div>
              <div class="second-block">
                <span class="fz-27 openSans-400 pd-header-value">{{setsApplied.length}} </span>
                <span class="fz-16 openSans-100 pd-header-label d-block">L.I. Pipelines used</span>
              </div>
            </b-row>
            <b-row class="pd-content-first-row">
              <div>
                <span class="openSans-100 fz-22 pd-content-first-section">Ingestion Sources <span class="pd-content-first-section-count">({{project.files.length}} files)</span></span>
                <div v-for="entry in ingestionSources" :key="entry.name" class="pd-content-first-section-item">
                  <b-row class="m-0">
                    <div>
                      <span class="openSans-100 fz-18 d-block">{{entry.name}}</span>
                      <span class="openSans-400 fz-14 pd-item-counter">{{entry.fileCount}} {{entry.fileType}} documents ingested</span>
                    </div>
                    <div class="pd-item-action">
                      <img :src="'/assets/images/icon_delete.svg'"/>
                    </div>
                  </b-row>
                </div>
              </div>
              <div v-if="setsApplied" class="second-item-block">
                <span class="openSans-100 fz-22 pd-content-first-section">Utility Sets applied</span>
                  <div v-for="set in setsApplied" :key="set.id" class="pd-content-first-section-item">
                    <b-row class="m-0" v-if="set.ontologies.length > 0">
                      <div class="pd-icon-box">
                        <img :src="'/assets/images/icon_utility_changetracking.svg'"/>
                      </div>
                      <div>
                        <span class="openSans-100 fz-12 pd-item-version d-block">v1.03</span>
                        <span class="openSans-400 fz-19 d-block pd-utility-label">{{set.name}} </span>
                        <span class="openSans-100 fz-18 d-block pd-sets-ontologies">Ontologies Applied: {{set.ontologies.join(", ")}} </span>
                        <b-button class="button-primary pd-margin-button ta-center" :to="set.path">
                          <span class="sets-button">Edit Ontologies</span>
                        </b-button>
                      </div>
                    </b-row>
                </div>
              </div>
            </b-row>
          </b-tab>
          <template #tabs-end>
            <li class="margin-label-view tabs-display-margin-project openSans-400 fz-13">{{ $t('settings.menu.tab.myInstances.view') }}</li>
            <b-nav-item href="#" role="presentation" class="display-change-icons" @click="changeDisplay('detailed')">
                <img :class="cardDisplay == 'detailed' ? 'active-display' : ''" :src="'/assets/images/icon_detailed.svg'"/>
            </b-nav-item>
            <b-nav-item href="#" role="presentation" class="display-change-icons margin-simplified" @click="changeDisplay('simplified')">
                <img :class="cardDisplay == 'simplified' ? 'active-display' : ''" :src="'/assets/images/icon_di_documentview.svg'"/>
            </b-nav-item>
          </template>
        </b-tabs>
      </b-row>
    </div>
  </div>
</template>

<script>
import MyProjectHeader from '@/modules/common/components/MyProjectHeader'
import ProjectService from "@/modules/projects/services/ProjectService"
import MyFilesList from '@/modules/projects/components/MyFilesList'
import ProjectUtilityList from "@/modules/projects/components/ProjectUtilityList";
import ConfigureIngestionTab from '@/modules/projects/views/ConfigureIngestionTab.vue'
import UtilitySetsService from "@/modules/utilitySets/services/UtilitySetsService";

var currentProject = {}

export default {
  name: "Project",
  components: { MyProjectHeader, MyFilesList, ProjectUtilityList, ConfigureIngestionTab },
  data () {
    return {
      ingestionSources: [],
      setsApplied: [],
      pipelineSources: [],
      step: 1,
      cardDisplay: 'simplified',
      pending: false,
      project: {
        projectId: null,
        docId: null,
        botName: '',
        name: '',
        isDayAgo: false,
        started: '',
        ingested: false,
        files: [],
        pipelinesUsed: []
      },
      showInput: false,
      suggested: [],
      utilities: [],
      polling: null
    }
  },
  methods: {
    changeDisplay: function (display) {
      display == 'detailed' ? this.cardDisplay = 'detailed' : this.cardDisplay = 'simplified'
    },
    initializeProject() {
      this.step = currentProject.step
      this.project.name = currentProject.project_name
      this.project.botName = this.$store.getters.getInstance
      this.project.ingested = currentProject.ingestion_completed
      this.project.projectId = currentProject.id
      this.project.started = currentProject.created
      this.project.pipelinesUsed = currentProject.pipeline_configs
      this.updateDocumentList(currentProject.documents_data)

      let created = new Date(this.project.started)
      let today = new Date(Date.now())
      this.project.isDayAgo = (today - created) <= 86400000

      let ingestionConfig = currentProject.pipelineConfigs['INGESTION']
      this.pending = ingestionConfig && ingestionConfig.status == "PENDING"

      if (!this.project.ingested) {
        this.polling = setInterval(() => {
          ProjectService.get(this.$router.currentRoute.params.projectId,
                             this.$router.currentRoute.params.bot).then(response => {
            this.updateDocumentList(response.data.documents_data)
            this.project.ingested = response.data.ingestion_completed
            this.pending = !this.project.ingested
            if (this.project.ingested) {
              clearInterval(this.polling)
            }
          }, error => {
            console.log(error)
          })
        }, 5000)
      }
      UtilitySetsService.getUtilities(this.$route.params.bot).then(response => {
        response.data.default_utilities.forEach(utility => {
          if(response.data.utilities.utilities.includes(Object.keys(utility)[0])){
            let dataUtility = utility[Object.keys(utility)[0]]
            this.utilities.push({
              title: dataUtility.title,
              descriptionKey: dataUtility['description_key'],
              iconUtility: dataUtility['icon_utility'],
              enable: dataUtility.enable,
              version: dataUtility.version,
              type: dataUtility.type,
              path: dataUtility.relative ? `/${this.$route.params.bot}/${dataUtility.relative}/${this.$route.params.projectId}`: null
            })
          }
        })
      })
    },
    updateDocumentList(documents) {
      this.project.files = []
      documents.forEach(file => {
        this.project.files.push({
          name: file.title,
          id: file.doc_id,
          mymeType: file.mime_type,
          status: file.status,
          path: `/${this.$route.params.bot}/projects/${this.project.projectId}/${file.doc_id}`,
          uploaded: file.uploaded
        })
      })
    },
    removeProject() {
      ProjectService.remove(this.project.projectId).then( () => {
        this.$router.push(`/${this.$store.getters.getInstance}/projects`)
      }, error => {
        console.log(error)
      })
    },
    showMsgBox() {
      let boxOne = ''
      this.$refs.popover.$emit('close')
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this project?', {
        title: 'Wait',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'popUp-primary',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'popUp-cancel',
        centered: true,
        hideHeaderClose: false,
        footerClass: 'p-2'
      })
        .then(value => {
          boxOne = value
          if(boxOne === true) {
            this.preloader = true
            this.removeProject()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancelEdit() {
      this.showInput = false
    },
    renameProject() {
      this.showInput = true
      this.$nextTick(() => this.$refs.projectName.focus())
      this.$refs.popover.$emit('close')
    },
    submitEdit() {
      this.showInput = false
      let renamed = {
        project_name: this.project.name,
        project_id: this.project.projectId
      }
      ProjectService.rename(renamed)
    },
    resumeProjectData() {
      var pipelineData = {
        'INGESTION': {
          count: 0,
          fileCount: 0
        },
        'LANGUAGE_INTELIGENCE': {
          count: 0
        },
        'MEDICAL_GUIDELINES': {
          count: 0
        },
        'SUMMARISATION': {
          count: 0
        }
      }
      this.project.pipelinesUsed.forEach(entry => {
        var pipeConf = pipelineData[entry.type]
        pipeConf.count += 1
        if (entry.type == 'INGESTION') {
          pipeConf.name = `${ entry.name } ${ entry.created.split("T")[0] }`
          pipeConf.fileCount += entry.manifest.sources.length
          pipeConf.fileType = entry.manifest.sources[0].type
        } else {
          pipeConf.name = 'Document Inteligence'
          pipeConf.id = `${ entry.name } ${ entry.created.split("T")[0] }`
          pipeConf.ontologies = entry.pipeline_data.ontologies === undefined ? [] : entry.pipeline_data.ontologies
          pipeConf.path = `/${this.$route.params.bot}/language-inteligence/${this.project.projectId}/`
        }
      })
      if (pipelineData['INGESTION'].count != 0) {
        this.ingestionSources.push(pipelineData['INGESTION'])
        this.pipelineSources.push({
          type: 'INGESTION',
          count: pipelineData['INGESTION'].count
        })
      }
      if (pipelineData['LANGUAGE_INTELIGENCE'].count != 0) {
        this.setsApplied.push(pipelineData['LANGUAGE_INTELIGENCE'])
        this.pipelineSources.push({
          type: 'LANGUAGE_INTELIGENCE',
          count: pipelineData['LANGUAGE_INTELIGENCE'].count
        })
      }
      if (pipelineData['MEDICAL_GUIDELINES'].count != 0) {
        this.setsApplied.push(pipelineData['MEDICAL_GUIDELINES'])
        this.pipelineSources.push({
          type: 'MEDICAL_GUIDELINES',
          count: pipelineData['MEDICAL_GUIDELINES'].count
        })
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  beforeRouteEnter(to, from, next) {
    ProjectService.get(to.params.projectId, to.params.bot).then(response => {
      let configs = {}
      let project = response.data
      project.pipeline_configs.forEach(config => {
        configs[config.type] = config
      })
      project['pipelineConfigs'] = configs
      // before u were going to Enrich Document
      // now we have multiple documents so we flip the results
      project['step'] = from.name === "File" || from.name == null ? 0 : 2
      currentProject = project
      next()
    }, error => {
      console.log(error)
      let botName = to.params.bot
      return next(`/${botName}/projects`)
    })
  },
  created() {
    this.initializeProject()
    this.step = this.$route.query.step*1
    this.resumeProjectData()
  }
}
</script>

<style lang="scss" scoped>

.pd-sets-ontologies {
  max-width: 340px;
}

.sets-button {
  vertical-align: sub;
}

.project-ingestion-source-filetype {
  max-width:100px;
  margin-bottom:0px;
}

div.col-8{
    border: 4px solid white;
    box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.25);
}
.margin-dot {
  margin: auto 0px auto 10px;
  cursor: pointer;
}

.project-action-list li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
}

.project-action-list li span {
  vertical-align: sub;
  margin-left: 19px;
  color: var(--viridian);
  font-size: 15px;
}

.padding-zero {
  padding: 0px;
}

.margin-label-view {
  margin: auto 0px;
}
</style>

