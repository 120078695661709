import Repository from '@/modules/common/utils/Repository.js'

export default {
  getUtilities(botName) {
    return Repository.dbService.get('tools/get_utilities', {
      params: {
        'bot_name': botName
      }
    })
  }
}