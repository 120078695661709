<template>
  <div class="col-12 margin-cards" :class="loadingStatus ? 'cursor-wait' : ''">
    <UploadFilesSteps
      :project="project"
      :ingestion-sources="sources"
      :save-project-callback="startLoadingStatus">
    </UploadFilesSteps>
  </div>
</template>

<script>
import UploadFilesSteps from '@/modules/projects/views/UploadFilesSteps.vue'

export default {

  name: 'ConfigureIngestionTab',

  components: { UploadFilesSteps },
  props: {
    project: Object
  },
  data () {
    return {
      loadingStatus: false,
      sources: [
        { id: 'upload-file', title: 'ingestionProject.cards.card.uploadFile.title', buttonLabel: 'ingestionProject.cards.chooseFile', available: true, icon: 'icon_cip_upload.svg' },
        { id: 'url', title: 'ingestionProject.cards.card.url.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_url.svg' },
        { id: 'api', title: 'ingestionProject.cards.card.api.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_api.svg' },
        { id: 'audio', title: 'ingestionProject.cards.card.audio.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_audio.svg' },
        { id: 'youtube-vimeo', title: 'ingestionProject.cards.card.youtubeVimeo', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_youtube.svg' },
        { id: 'aws', title: 'ingestionProject.cards.card.aws.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_aws.svg' },
        { id: 'dropbox', title: 'ingestionProject.cards.card.dropbox.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_dropbox.svg' },
        { id: 'microsoft-azure', title: 'ingestionProject.cards.card.microsoftAzure.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_azure.svg' },
        { id: 'google-drive', title: 'ingestionProject.cards.card.googleDrive.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_drive.svg' },
        { id: 'veeva', title: 'ingestionProject.cards.card.veeva.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_veeva.svg' }
      ]
    }
  },
  methods: {
    startLoadingStatus() {
      this.loadingStatus = true
    }
  }
}
</script>