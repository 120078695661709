<template>
  <div class="margin-dots my-auto">
    <div v-if="document.id">
      <img :id="'popover'+document.id" class="margin-dot" :src="'/assets/images/icon_moreoptions.svg'"/>
      <b-popover :ref="`popover-${document.id}`" class="my-project-popover" :target="'popover'+document.id"
                 placement="bottom">
        <div>
          <ul class="project-action-list padding-zero">
            <li @click="showMsgBox(document, $refs)">
              <img :src="'/assets/images/icon_delete.svg'"/>
              <span>Remove</span>
            </li>
          </ul>
        </div>
      </b-popover>
    </div>
    <div v-else>
      <img :id="'popover'+document.doc_id" class="margin-dot" :src="'/assets/images/icon_moreoptions.svg'"/>
      <b-popover :ref="`popover-${document.doc_id}`" class="my-project-popover" :target="'popover'+document.doc_id"
                 placement="bottom">
        <div>
          <ul class="project-action-list padding-zero">
            <li @click="showMsgBox(document.doc_id, $refs)">
              <img :src="'/assets/images/icon_delete.svg'"/>
              <span>Remove</span>
            </li>
          </ul>
        </div>
      </b-popover>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popover-dots',
  props: {
    document: {},
    showMsgBox: Function
  }
}
</script>
<style lang="css" scoped>
.project-action-list li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
}

.project-action-list li span {
  vertical-align: sub;
  margin-left: 19px;
  color: var(--viridian);
  font-size: 15px;
}

.padding-zero {
  padding: 0px;
}

.margin-dots {
  margin-left: auto;
  margin-right: 20px
}

</style>