<template>
  <b-container v-if="utilitySets.length" class="bv-example-row col-12 bg-white">
    <div class="text-left">
      <h3 class="section-title-right-line openSans-100 fz-23">{{ title }}
      </h3>
    </div>
    <b-row>
      <feature-card
        v-for="set in utilitySets" :key="set.title"
        :utility-set="set"
        :button-label="$t('proyect.create.proyectCardButton')"
        :show-button-icon="false"></feature-card>
    </b-row>
  </b-container>
</template>
<script>
import FeatureCard from "@/modules/utilitySets/components/FeatureCard"

export default {
  name: 'project-utility-list',
  components: { FeatureCard },
  props: {
    utilitySets: Array,
    title: String
  }
}
</script>
