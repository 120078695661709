<template>
  <div :class="cardType == 'myapps' ? 'col-6' : 'col-4'" class="col-4 margin-cards">
    <b-card class="percent-card" header-text-variant="white" header-tag="header">
      <b-row>
        <b-col lg="8" class="pb-2 text-align-left">
          <b-card-title class="utility-card-title openSans-400 fz-17"><span>{{ $t(utilitySet.title) }}</span></b-card-title>
          <b-card-text class="utility-card-text openSans-100 fz-14">{{ $t(utilitySet.descriptionKey) }}</b-card-text>
        </b-col>
        <b-col lg="4" class="pb-2">
          <div class="utility-card-icon-border-box">      
            <img :src="`/assets/images/${utilitySet.iconUtility}`">
          </div>
          <p class="utility-card-version">{{utilitySet.version}}</p>
        </b-col>
      </b-row>

      <template v-slot:footer >
        <b-row class="feature-card-row-adjustment">
          <b-col lg="6" class="pb-2">
            <b-button class="openSans-600 fz-12 ta-center button-secondary" size="sm" :to="utilitySet.path" :disabled="!utilitySet.path">
              <b-icon-box v-if="showButtonIcon"></b-icon-box><span class="mx-1">{{ buttonLabel }}</span>
            </b-button>
          </b-col>
          <b-col offset="2" lg="4" class="pb-2">
            <label class="utility-card-details-label">
              {{ $t('utilitySets.listUtility.featuredCard.details') }}
            </label>
          </b-col>
        </b-row>

      </template>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'feature-card',
  props: {
    utilitySet: Object,
    buttonLabel: String,
    showButtonIcon: Boolean,
    cardType: String
  },
  methods: {

  }
}
</script>
<style scoped>

a.btn-secondary.btn-sm{
  display:flex;
  max-width:140px;
}

.button-secondary svg{
  margin-right: 0.25rem !important;
}

span.mx-1{
  margin: auto !important;
}

div.row.feature-card-row-adjustment{
  margin-left:0px !important;
}

div.row.feature-card-row-adjustment div.col-lg-6{
  text-align:left;
  padding-left:0px;
}

.margin-cards{
  margin-bottom:2%
}

.card-footer{
  background-color:rgba(9, 96, 159, 0.04)
}

.percent-card {
  min-height: 250px
}

.utility-card-icon-border-box{
  border:1px solid #ebebeb;
  width:100px; 
  height:100px;
  display:table-cell;
  vertical-align:middle
}

div.utility-card-icon-border-box img{
  margin: 0 auto;
}

.utility-card-version{
  margin-top:4%;text-align:left;   
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #888888;
}
.utility-card-details-label{
  font-size:12px;
  position: relative;
  top: calc(50% - 10px);
  color: var(--viridian);
  font-weight: bold;
}

b-card-text{
  font-size:14px;
}

</style>