<template>
  <div class="container-base myproj-header-container">
    <b-row class="li-header-initial-row">
      <h2 class="li-header-title openSans-100 fz-28" v-if="!showInput">{{ title | titleUpperCase}}</h2>
      <slot name="inputName"></slot>
      <h3 class="li-header-update-stamp openSans-400 fz-11 li-header-timestamp">
        <span v-if="isNew">{{ $t('proyect.general.new') }}</span> {{ $t('proyect.general.updatedLessThan') }} {{ lastUpdate | formatTime }}
      </h3>
      <br>
      <slot name="popover" v-if="this.$store.getters.canModifyLibrary"></slot>
    </b-row>
    <b-row class="li-header-second-row d-flex align-items-center">
      <div v-if="documentUrl"  class="d-flex">
        <h3 class="li-pipelines-used-title openSans-400 fz-11">{{ $t('proyect.general.viewDoc') }}</h3>
        <router-link class="text-left myproj-projectname" target="_blank" :to="'/' + documentUrl">
        <img :src="'/assets/images/icon_book.svg'" /></router-link>
      </div>
      <div class="d-flex align-items-center" v-if="pipelinesUsed !== undefined && pipelinesUsed.length > 0">
        <span class="library-card-date openSans-400 fz-12 mx-2">{{ $t('proyect.general.liPipelinesUsed') }}</span>

        <span v-if="pipelineConfigsData['INGESTION'] > 0">
          <img class="pipelines-used-icons" :src="'/assets/images/icon_li_table_ingestion.svg'" />
          <span class="library-card-date openSans-400 fz-12 mx-2 pipelines-used-label">
            {{ $t('myProjects.cards.ingestion') }} <span v-if="pipelineConfigsData['INGESTION'] > 1">({{ pipelineConfigsData['INGESTION'] }})</span>
          </span>
        </span>
        <span v-if="pipelineConfigsData['LANGUAGE_INTELIGENCE'] > 0">
          <img class="pipelines-used-icons" :src="'/assets/images/icon_li_table_ontology.svg'" />
          <span class="library-card-date openSans-400 fz-12 mx-2 pipelines-used-label">
            {{ $t('myProjects.cards.ontologies') }}
            <span v-if="pipelineConfigsData['LANGUAGE_INTELIGENCE'] > 1">({{ pipelineConfigsData['LANGUAGE_INTELIGENCE'] }})</span>
          </span>
        </span>
      </div>
    </b-row>
  </div>
</template>

<script>
import tools from '@/modules/common/utils/tools'
export default {

  name: 'MyProjectHeader',

  data () {
    return {
      pipelineConfigsData: {
        'INGESTION': 0,
        'LANGUAGE_INTELIGENCE': 0,
        'SUMMARISATION': 0
      }
    }
  },
  props: {
    title: String,
    isNew: Boolean,
    lastUpdate: String,
    pipelinesUsed: Array,
    documentUrl: String,
    showInput: Boolean,
  },
  filters: {
    titleUpperCase: tools.titleUpperCase,
    formatTime: tools.formatTime
  },
  methods: {
    countPipelineConfigs() {
      if (this.pipelinesUsed !== undefined) {
        this.pipelinesUsed.forEach(pipelineConfig => {
          console.log('MyProjectHeader' + pipelineConfig.type)
          this.pipelineConfigsData[pipelineConfig.type] += 1
        })
      } else {
        console.log('MyProjectHeader pipelinesUsed is undefined')
      }
    }
  },
  created() {
    this.countPipelineConfigs()
  }
}
</script>

<style scoped>
div.li-header-second-row {
  margin-bottom:24px;
}
div.li-header-second-row div.d-flex {
  margin-right:20px;
}
div.li-header-second-row div.d-flex h3 {
  margin:auto;
}
div.li-header-second-row div.d-flex a {
  padding-left:0px;
}
div.li-header-second-row div.d-flex a img {
  margin-left:15px;
}
.myproj-header-container {
  margin-left: 10px;
}
.li-header-timestamp {
  margin-left: 15px;
}

.project-action-list li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
}
.project-action-list li:hover {
  background-color: var(--viridian);
}
.margin-dot {
  margin: auto 0px auto 10px;
}
.input-margin {
  width: 25%;
}
</style>
